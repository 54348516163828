import classNames from 'classnames/bind';

import { Body, BodyProps } from '@/components/shared/typography/Body';
import { Initials } from '@/components/shared/Initials';

import style from './TinyUserText.module.sass';

const cx = classNames.bind(style);

type TinyUserTextProps = {
  firstName?: string;
  lastName?: string;
  nameBodySize?: BodyProps['size'];
  subText?: string;
  initialsWrapperClassName?: string;
};

export const TinyUserText = ({
  firstName,
  lastName,
  nameBodySize = 'small',
  subText,
  initialsWrapperClassName,
}: TinyUserTextProps) => {
  if (!firstName || !lastName) {
    return (
      <div className={style.subText}>
        {subText}
      </div>
    );
  }

  const initialsWrapperClassNames = cx('initialsWrapper', initialsWrapperClassName);

  return (
    <div className={style.wrapper}>
      <div className={style.sectionWithInitials}>
        <div className={initialsWrapperClassNames}>
          <Initials
            firstName={firstName}
            lastName={lastName}
            fontSize={45}
          />
        </div>
        <Body size={nameBodySize} className={style.name}>
          {firstName} {lastName}
        </Body>
      </div>
      {
        subText &&
        <div className={style.subText}>
          {subText}
        </div>
      }
    </div>
  );
};

export default TinyUserText;
