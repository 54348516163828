import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import {
  init as sentryInit,
  replayIntegration,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { App } from '@/components/App';
import { Loader } from '@/components/shared/loaders/Loader';
import { ErrorPage } from '@/components/ErrorPage';
import { Api } from '@/utils/api';
import { AuthHelper } from '@/utils/firebase';
import { Config, type EnvConfig } from '@/utils/environment';

import 'swiper/css';
import 'swiper/css/navigation';
import 'virtual:vite-plugin-sentry/sentry-config';

import '@/initializers/i18n';
import '@/initializers/yup';
import '@/styles/application.sass';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(<Loader />);

if (!window.location.pathname.includes('/app')) {
  window.history.replaceState(null, '', `/app${window.location.pathname}`);
}

const initialize = async () => {
  try {
    const response = await axios.get<EnvConfig>('/env.json');

    const config = response.data;

    await Config.initialize(config);

    sentryInit({
      dsn: config.VITE_SENTRY_DSN,
      environment: config.VITE_SENTRY_ENVIRONMENT,
      dist: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist,
      release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
      integrations: [
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.2,
      replaysOnErrorSampleRate: 1.0,
    });

    await Api.initialize(config);
    await AuthHelper.initialize(config);

    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  } catch (error) {
    console.error(error);
    root.render(<ErrorPage error={error instanceof Error ? error : undefined} />);
  }
};

initialize();
