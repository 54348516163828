import classNames from 'classnames/bind';
import { PropsWithChildren, forwardRef } from 'react';

import { Icon } from '@/components/shared/Icon';
import type { IconName } from '@/types/shared';

import style from './Badge.module.sass';

const cx = classNames.bind(style);

export type BadgeProps = PropsWithChildren<{
  variant:
  | 'success'
  | 'danger'
  | 'primary'
  | 'beige'
  | 'purple'
  | 'neutral'
  | 'orange'
  | 'info'
  | 'olive';
  sizeVariant?: 'small' | 'base' | 'large';
  iconName?: IconName;
  shapeVariant?: 'rectangle' | 'rounded';
  isOutlined?: boolean;
  className?: string;
}>;

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(({
  children,
  variant,
  sizeVariant = 'base',
  iconName,
  shapeVariant = 'rounded',
  isOutlined,
  className,
}, ref) => {
  const badgeClassName = cx(
    'badge',
    variant,
    sizeVariant,
    shapeVariant,
    className,
    {
      isOutlined,
      isOutlinedAndRounded: isOutlined && shapeVariant === 'rounded',
    },
  );

  const iconClassName = cx('icon', {
    isWithAdjacentText: !!children,
  });

  return (
    <span className={badgeClassName} ref={ref}>
      {
        iconName &&
        <Icon
          name={iconName}
          className={iconClassName}
        />
      }
      {children}
    </span>
  );
});

Badge.displayName = 'Badge';
